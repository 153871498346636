<template>
  <div class="notice-container">
    <el-row>
      <el-col class="notice-left" :span="17">
        <div class="content">
          <div class="notice-breadcrumb">
            <div>首页</div>
            <div>></div>
            <div>公告</div>
            <div>></div>
            <div class="last-text">公告详情</div>
          </div>
          <div class="notice-title">春节放假通知</div>
          <div class="notice-time">
            <div>发布时间： 2022/01/10 16:28:13</div>
            <div>浏览： 83次</div>
          </div>
          <div style="background-color: #FFF8F8;">
            <div class="notice-img-1">
              <img style="width: 100%;" src="./1.png" srcset="./1@2x.png 2x" alt="">
            </div>
            <div>

            </div>

            <div style="padding: 0 20px;">
              <div>
                尊敬的杭州里德通信、里德通信科技（重庆）用户：
              </div>
              <p style="margin-top: 15px;"> 您好！ </p>
              <p style="margin-top: 15px;"> 2022年春节将至，里德放假具体安排如下：</p>
              <p style="font-weight: bold;margin-top: 15px;"> 杭州里德通信：</p>
              <p style="margin-top: 15px;"> 2022年01月25日（腊月二十三）—2022年02月09日（正月初九）放假，2022年02月10日（正月初十）正式上班！</p>
              <p style="font-weight: bold;margin-top: 15px;"> 里德通信科技（重庆）：</p>
              <p style="margin-top: 15px;"> 2022年01月24日（腊月二十二）—2022年02月07日（正月初七）放假，2022年02月08日（正月初八）正式上班！</p>
              <div style="margin-top: 15px;">
                <p style="margin-top: 50px;">祝：</p>
                <p>大家春节快乐，财源广进，客似云来！</p>
              </div>

              <div class="btmflex">
                <div class="sonbtmflex">
                  <div>行政管理部</div>
                  <div style="margin-top: 20px;">2022年01月10日</div>
                </div>
              </div>
            </div>

            <div style="height: 100px;">
            </div>

          </div>

          <div class="notice-footer">
            <div class="notice-prev">
              <router-link tag="span" to="/notice10">
                < 上一篇</router-link>
            </div>
            <div class="notice-next">
              <router-link tag="span" to="/notice8">下一篇 ></router-link>
            </div>
          </div>
        </div>
      </el-col>
      <el-col class="notice-right" :span="7">
        <div class="content">
          <div class="title">最新公告</div>
          <div>
            <div class="notice-other-1 pointer">
              <router-link tag="span" to="/notice14">童年，是一只载着无数快乐的小船；童年,……</router-link>
            </div>
            <div class="notice-other-time-1">2022-06-01 15:26</div>

            <div class="notice-other-2 pointer">
              <router-link tag="span" to="/notice13">小鸟为天空而歌，蜜蜂为花朵而歌，今天……</router-link>
            </div>
            <div class="notice-other-time-2">2022-05-01 10:39</div>
            <div class="notice-other-2 pointer">
              <router-link tag="span" to="/notice12">为助力健康亚运建设，保障劳动者职业健……</router-link>
            </div>
            <div class="notice-other-time-2">2022-04-26 16:52</div>
            <div class="notice-other-2 pointer">
              <router-link tag="span" to="/notice11">2022年3月24日，杭州里德通信有限公司桐……</router-link>
            </div>
            <div class="notice-other-time-2">2022-04-25 11:26</div>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
@import '@/styles/common.scss';

.notice-container {
  padding: 24px gap() 56px;
}

.notice-left {
  .content {
    background-color: #fff;
    margin-right: 12px;
    padding: 16px 32px 40px;
    font-size: 16px;
    .btmflex {
      height: 50px;
      margin-top: 60px;
      display: flex;
      justify-content: right;
      .sonbtmflex {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
      }
    }
  }

  p {
    text-indent: 2em;
    line-height: 1.5;
  }
}

.notice-breadcrumb {
  display: flex;
  font-size: 14px;
  margin-bottom: 20px;

  .last-text {
    color: #a6a6a6;
  }
}

.notice-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.notice-time {
  display: flex;
  color: #a6a6a6;
  font-size: 14px;
  padding-bottom: 8px;
  margin-bottom: 20px;
  border-bottom: 1px solid #d4d4d4;

  div:first-of-type {
    margin-right: 32px;
  }
}

.notice-img-1,
.notice-img-2 {
  margin-top: 16px;
  margin-bottom: 32px;
  text-align: center;
}

.notice-img-3 {
  img {
    display: block;
    margin: auto;

    &:first-of-type {
      margin-top: 16px;
      margin-bottom: 24px;
    }

    &:last-of-type {
      margin-bottom: 32px;
    }
  }
}

.notice-footer {
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
  margin-top: 64px;
  border-top: 1px solid #d4d4d4;
}

.notice-prev {
  color: #da261d;
  cursor: pointer;
}

.notice-next {
  color: #da261d;
  cursor: pointer;
}

.notice-right {
  .content {
    background-color: #fff;
    margin-right: 12px;
    padding: 24px 24px 400px;
    box-sizing: border-box;
    font-size: 16px;

    .title {
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 18px;
    }
  }
}

.notice-other-time-1,
.notice-other-time-2 {
  padding: 8px 0;
  color: #a6a6a6;
  border-bottom: 1px solid #d4d4d4;
}

.notice-other-2 {
  margin-top: 32px;
}
</style>
